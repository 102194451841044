import { translate } from "@ollie-sports/i18n";
import {
  PrettyCoolTextInputWithLabel,
  PrettyCoolTextAreaWithLabel,
  Form,
  FormRef,
  PrettyCoolSelectInput
} from "../../../components/Form";
import { CoolCheckboxInput } from "../../../components/Inputs/CoolCheckboxInput";
import { LINK_REGEX, EMAIL_REGEX } from "../../../utils/Regexes";
import { Org, OrgSettings } from "@ollie-sports/models";
import { useImmutableState } from "../../../utils/useImmutableState";
import { useRef, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { common__magicDeleteValue } from "@ollie-sports/core/dist/api";
import { getBifrost } from "../../../services/bifrost.service";
import { openErrorToast } from "../../../utils/openErrorToast";
import { InfoTooltipIcon } from "../../../components/InfoTooltip";
import { StyledButton } from "../../../components/StyledButton";
import { Prompt } from "react-router";
import { dequal } from "dequal";
import { useOrgSettings } from "../../../hooks/useOrgSettings";
import { useOrg } from "../../../hooks/useOrg";
import { TouchableOpacity, View } from "react-native-web";
import { ShadowView } from "../../../components/ShadowView";
import { AddCircle } from "@material-ui/icons";
import { downscaleImage, fileToBase64 } from "../../../utils/fileUtils";
import { addHeightAndWidthToURI } from "../../../utils/imageUrlHelpers";
import { COLORS, api, isValidHex, ensureColorHasHashtag, timezoneOptions } from "@ollie-sports/core";
import { getCurrentUserAccountId } from "../../../hooks/commonDataUtils";
import { deEqualIgnoring__UpdatedAtMS } from "../OrgSettings/helpers";

export function OrgSettingsDetails(p: { initialOrgSettings: OrgSettings; initialOrg: Org }) {
  const [orgSettings, setOrgSettings] = useImmutableState<OrgSettings>(p.initialOrgSettings);
  const [org, setOrg] = useImmutableState<Org>(p.initialOrg);
  const { orgSettings: subscribedOrgSettings } = useOrgSettings({ orgId: p.initialOrg.id });
  const { org: subscribedOrg } = useOrg({ orgId: p.initialOrg.id });
  const INPUT_ID = useRef(Math.random() + "").current;
  const [isUploading, setIsUploading] = useState(false);

  return (
    <Form
      children={isFormValid => {
        return (
          <>
            <Prompt
              message={translate({ defaultMessage: "Are you sure you wish to navigate? You have unsaved changes." })}
              when={!deEqualIgnoring__UpdatedAtMS(subscribedOrgSettings, orgSettings)}
            />

            <PrettyCoolTextInputWithLabel
              label={translate.common.Name}
              onChange={newVal => {
                setOrg({ name: newVal ?? "" });
              }}
              value={org.name}
            />
            <PrettyCoolTextInputWithLabel
              label={translate.common.ShortName}
              onChange={newVal => {
                setOrg({ shortName: newVal ?? "" });
              }}
              style={{ marginTop: 16 }}
              isRequired
              validate={val => {
                if (val && val.length > 12) {
                  return translate({ defaultMessage: "Must be 12 characters or less" });
                }
                return "";
              }}
              value={org.shortName}
            />
            <View style={{ marginTop: 16, marginBottom: 16 }}>
              <label className="text-sm font-extrabold">{translate({ defaultMessage: "Logo" })}</label>

              <ShadowView
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 10,
                  marginTop: 8,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <input
                  id={INPUT_ID}
                  accept={"image/*"}
                  multiple={false}
                  onChange={async event => {
                    var file = event.target.files?.[0];
                    if (file) {
                      setIsUploading(true);
                      const base64 = await fileToBase64(file);
                      const { blob, height, width } = await downscaleImage(base64, 500);
                      if (blob && file) {
                        try {
                          const firebaseUrl = await api.org__client__uploadLogo({
                            blob,
                            orgId: p.initialOrg.id
                          });
                          if (firebaseUrl) {
                            setOrg({
                              logoUri: addHeightAndWidthToURI({
                                uri: firebaseUrl,
                                height,
                                width
                              })
                            });
                          }
                        } catch (e) {
                          openErrorToast(
                            translate({
                              defaultMessage:
                                "There was a problem uploading the image. Please try again or contact support@olliesports.com"
                            })
                          );
                        }
                      }
                      setIsUploading(false);
                    }
                  }}
                  type="file"
                  style={{ display: "none" }}
                />
                <TouchableOpacity
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 15,
                    width: "100%",
                    height: "100%"
                  }}
                  onPress={() => {
                    document.getElementById(INPUT_ID)!.click();
                  }}
                >
                  {org.logoUri ? (
                    <img src={org.logoUri} className="w-auto h-auto max-w-96 max-h-96" alt="" />
                  ) : isUploading ? (
                    <CircularProgress />
                  ) : (
                    <AddCircle
                      style={{
                        backgroundColor: COLORS.white,
                        color: COLORS.grey_07,
                        height: 40,
                        width: 40
                      }}
                    />
                  )}
                </TouchableOpacity>
              </ShadowView>
            </View>

            <label className="flex text-sm font-extrabold items-center">{translate.common.TimeZone}</label>
            <PrettyCoolSelectInput
              onChange={newVal => {
                setOrg({ timezone: newVal });
              }}
              isRequired
              options={timezoneOptions}
              value={org.timezone}
              containerStyle={{ marginBottom: 8 }}
            />

            <PrettyCoolTextInputWithLabel
              label={translate.common.WebsiteURL}
              onChange={newVal => {
                setOrgSettings({ websiteUrl: newVal?.toLowerCase() ?? "" });
              }}
              validate={v => {
                if (v) {
                  if (!v.match(LINK_REGEX)) {
                    return translate({ defaultMessage: "Must be a valid url" });
                  }
                }
                return "";
              }}
              inputProps={{ placeholder: translate({ defaultMessage: "Enter Wesbite URL" }) }}
              value={orgSettings.websiteUrl ?? ""}
            />
            <View style={{ marginTop: 8, flexDirection: "row", alignItems: "flex-start" }}>
              <View style={{ flex: 1 }}>
                <PrettyCoolTextInputWithLabel
                  label={translate({ defaultMessage: "Primary Color (3 or 6 digit representation)" })}
                  onChange={newVal => {
                    setOrgSettings({ primaryColor: newVal?.toLowerCase() ?? "" });
                  }}
                  style={{ flex: 1 }}
                  validate={v => {
                    if (!v) {
                      return "";
                    }

                    if (!v.match(/^([0-9A-F]{3}){1,2}$/i)) {
                      return translate({ defaultMessage: "Must be 3 or 6 digits and only contain 0-9 and A-F" });
                    }
                    return "";
                  }}
                  inputProps={{ placeholder: translate({ defaultMessage: "Enter a 3 or 6 digit color" }) }}
                  value={orgSettings.primaryColor ?? ""}
                />
              </View>
              <View
                style={{
                  height: 40,
                  width: 40,
                  marginLeft: 16,
                  borderRadius: 8,
                  borderWidth: 1,
                  marginTop: 24,
                  borderColor: COLORS.grey_45,
                  backgroundColor:
                    orgSettings.primaryColor && isValidHex(orgSettings.primaryColor)
                      ? ensureColorHasHashtag(orgSettings.primaryColor)
                      : COLORS.white
                }}
              />
            </View>
            <PrettyCoolTextInputWithLabel
              label={translate({ defaultMessage: "Default Reply To Email Address" })}
              style={{ marginTop: 16 }}
              inputProps={{ placeholder: translate({ defaultMessage: "Enter Email Address" }) }}
              onChange={newVal => {
                setOrgSettings({ defaultReplyToEmailAddress: newVal?.toLowerCase() ?? "" });
              }}
              validate={v => {
                if (v) {
                  if (!v.match(EMAIL_REGEX)) {
                    return translate({ defaultMessage: "Must be a valid email" });
                  }
                }
                return "";
              }}
              value={orgSettings.defaultReplyToEmailAddress ?? ""}
            />
            <PrettyCoolTextInputWithLabel
              label={translate({ defaultMessage: "Facebook URL" })}
              style={{ marginTop: 16 }}
              onChange={newVal => {
                setOrgSettings({ socialUrls: { ...orgSettings.socialUrls, facebookUrl: newVal?.toLowerCase() ?? "" } });
              }}
              validate={v => {
                if (v) {
                  if (!v.match(LINK_REGEX)) {
                    return translate({ defaultMessage: "Must be a valid url" });
                  }
                }
                return "";
              }}
              inputProps={{ placeholder: translate({ defaultMessage: "Enter Facebook URL" }) }}
              value={orgSettings.socialUrls?.facebookUrl ?? ""}
            />
            <PrettyCoolTextInputWithLabel
              label={translate({ defaultMessage: "Instagram URL" })}
              style={{ marginTop: 16 }}
              onChange={newVal => {
                setOrgSettings({ socialUrls: { ...orgSettings.socialUrls, instagramUrl: newVal?.toLowerCase() ?? "" } });
              }}
              validate={v => {
                if (v) {
                  if (!v.match(LINK_REGEX)) {
                    return translate({ defaultMessage: "Must be a valid url" });
                  }
                }
                return "";
              }}
              inputProps={{ placeholder: translate({ defaultMessage: "Enter Instagram URL" }) }}
              value={orgSettings.socialUrls?.instagramUrl ?? ""}
            />
            <PrettyCoolTextInputWithLabel
              label={translate({ defaultMessage: "Twitter URL" })}
              style={{ marginTop: 16 }}
              onChange={newVal => {
                setOrgSettings({ socialUrls: { ...orgSettings.socialUrls, twitterUrl: newVal?.toLowerCase() ?? "" } });
              }}
              validate={v => {
                if (v) {
                  if (!v.match(LINK_REGEX)) {
                    return translate({ defaultMessage: "Must be a valid url" });
                  }
                }
                return "";
              }}
              inputProps={{ placeholder: translate({ defaultMessage: "Enter Twitter URL" }) }}
              value={orgSettings.socialUrls?.twitterUrl ?? ""}
            />

            <StyledButton
              disabled={!isFormValid}
              className="mt-4"
              text={translate.common.Save}
              onClick={async () => {
                try {
                  await getBifrost().org__server__updateOrg.fetchServer({
                    id: org.id,
                    org: {
                      name: org.name,
                      shortName: org.shortName,
                      logoUri: org.logoUri ?? common__magicDeleteValue(),
                      timezone: org.timezone
                    },
                    orgSettings: {
                      websiteUrl: orgSettings.websiteUrl ?? common__magicDeleteValue(),
                      defaultReplyToEmailAddress: orgSettings.defaultReplyToEmailAddress ?? common__magicDeleteValue(),
                      primaryColor: orgSettings.primaryColor ?? common__magicDeleteValue(),
                      socialUrls: {
                        facebookUrl: orgSettings?.socialUrls?.facebookUrl ?? common__magicDeleteValue(),
                        instagramUrl: orgSettings?.socialUrls?.instagramUrl ?? common__magicDeleteValue(),
                        twitterUrl: orgSettings?.socialUrls?.twitterUrl ?? common__magicDeleteValue()
                      }
                    },
                    selfAccountId: getCurrentUserAccountId()
                  });
                } catch (e) {
                  openErrorToast(
                    translate({
                      defaultMessage: "There was a problem updating the org. Please try again or contact support@olliesports.com"
                    })
                  );
                }
              }}
            />
          </>
        );
      }}
    />
  );
}

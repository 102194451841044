import { Typography, Box, SvgIcon } from "@material-ui/core";
import { View } from "react-native-web";
import _ from "lodash";
import { Org } from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { useParams } from "react-router-dom";
import { useOrg } from "../../hooks/useOrg";
import CoolerTable, { CoolerTableMethods } from "../../components/CoolerTable";
import { getBifrost } from "../../services/bifrost.service";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { useRef, useState } from "react";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { useOrgTeamTags } from "../../hooks/useOrgTeamTags";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { MoreHorizontal } from "react-feather";
import { LoadingIndicator } from "../../utils/openLoadingIndicator";
import moment from "moment";
import { StyledText } from "../../components/StyledText";

export default function OrgReportsSchedulePaymentsByMonth() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org, isLoading } = useOrg({ orgId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading ? (
          <CenteredLoader />
        ) : org ? (
          <OrgReportsSchedulePaymentsByMonthInner org={org} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load org" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgReportsSchedulePaymentsByMonthInner(p: { org: Org }) {
  const coolerTableRef = useRef<CoolerTableMethods>(null);
  const { data: reportData, isFetched } = getBifrost().reports__server__getScheduledPaymentsByMonthReport.useServer(
    {
      orgId: p.org.id
    },
    { notifyOnMetaDataChanges: true }
  );

  return (
    <div>
      <div className="absolute inset-0">
        <LoadingIndicator isVisible={!isFetched} />
      </div>
      <div className="flex flex-row">
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4 mb-4">{translate({ defaultMessage: "Scheduled Payments By Month" })}</h1>
        <ActionButtonDropdown
          style={{ marginLeft: "auto" }}
          color="secondary"
          variant="contained"
          actions={[
            {
              key: "export",
              label: () => translate.common.Export,
              onClick: async () => {
                coolerTableRef.current?.downloadCurrentDataToCSV("scheduled-payments-by-month.csv");
              }
            }
          ]}
        >
          <span style={{ fontWeight: "bold", marginRight: 6 }}>{translate({ defaultMessage: "Actions" })}</span>
          <SvgIcon style={{ paddingRight: 6 }}>
            <MoreHorizontal />
          </SvgIcon>
        </ActionButtonDropdown>
      </div>
      <div style={{ paddingBottom: 16 }}>
        <StyledText>
          {translate({
            defaultMessage:
              "The amount includes payment plan installments that have not been paid for both registration and manual invoices"
          })}
        </StyledText>
      </div>
      <CoolerTable
        methodsRef={coolerTableRef}
        paginationOptions={{
          defaultPageSize: 25,
          pageSizeOptions: [25, 50],
          persistenceKey: "scheduled-payments-by-month"
        }}
        items={reportData ?? []}
        defaultSortSettings={{
          dir: "desc",
          label: translate.common.Month
        }}
        columnDefs={[
          {
            label: translate.common.Month,
            getValue(item) {
              const date = moment({ year: item.year, month: item.month });
              return dateFormatters.month(date.toDate(), getCurrentLocale()) + " " + item.year;
            },
            sortItems(items, dir) {
              return _.orderBy(items, [a => a.year, a => a.month], [dir, dir]);
            }
          },
          {
            label: translate.common.Amount,
            getValue(item) {
              return `$${item.amount}`;
            },
            sortItems(items, dir) {
              return _.orderBy(items, a => a.amount, dir);
            }
          }
        ]}
        getItemKey={a => `${a.month}-${a.year}`}
      />
    </div>
  );
}

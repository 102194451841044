import { Typography } from "@material-ui/core";
import _ from "lodash";
import {
  Org,
  OrgCoupon,
  // OrgCouponPaymentPlanApplicationMethod,
  OrgCouponType,
  OrgCouponUseType,
  PlayerBundleId
} from "@ollie-sports/models";
import { getCurrentLocale, translate } from "@ollie-sports/i18n";
import { getBifrost } from "../../services/bifrost.service";
import { COLORS } from "@ollie-sports/core";
import { useEffect, useState } from "react";
import { useImmutableState } from "../../utils/useImmutableState";
import { Form, PrettyCoolDateInput, PrettyCoolSelectInput, PrettyCoolTextInputWithLabel } from "../../components/Form";
import moment from "moment";
import { DistributiveOmit } from "react-redux";
import AsyncSelect from "react-select/async";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { usePromise } from "../../utils/hooks/usePromise";
import { openModal } from "../../components/modals/imperativeModal";
import { dequal } from "dequal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { useOrgSettings } from "../../hooks/useOrgSettings";
import { View } from "react-native-web";
import { TextButton } from "../../components/TextButton";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import { Expando } from "../../components/Expando";
import { OrgInvoicePayPreviewInner, OrgInvoicePreviewForCouponOrPaymentPlan } from "./OrgInvoicePayPreview";
import { StyledText } from "../../components/StyledText";
import { CenteredLoader } from "../../components/CenteredLoader";

type Props = {
  type: "create" | "edit";
  org: Org;
  initialOrgCoupon?: OrgCoupon;
  playerBundleId?: PlayerBundleId;
  onComplete?: () => Promise<void>;
};

export function openOrgCouponsAddEditModal(p: Props) {
  return new Promise<void>(res => {
    const modal = openModal({
      body: (
        <OrgCouponsAddEditModal
          {...p}
          onRequestDismiss={() => {
            modal.close();
            res();
          }}
        />
      )
    });
  });
}

function OrgCouponsAddEditModal(p: Props & { onRequestDismiss: () => void }) {
  const initialOrgCoupon = p.initialOrgCoupon ?? {
    orgId: p.org.id,
    playerBundleId: p.playerBundleId
  };
  const [orgCoupon, setOrgCoupon] = useImmutableState<Partial<OrgCoupon>>(initialOrgCoupon);
  const [isPreviewExpanded, setIsPreviewExpanded] = useState(false);
  const [hasUpdatedPlayerBundleIdsAtLeastOnce, setHasUpdatedPlayerBundleIdsAtLeastOnce] = useState(false);
  const [hasLoadedInitialPlayerBundles, setHasLoadedInitialPlayerBundles] = useState(false);
  const initialPlayerBundleIds = p.initialOrgCoupon?.playerBundleIds
    ? Object.keys(p.initialOrgCoupon.playerBundleIds)
    : p.playerBundleId
    ? [p.playerBundleId]
    : [];

  const { data: playerBundles } = getBifrost().playerBundle__server__getPrettyPlayerBundles.useServer(
    {
      ids: hasUpdatedPlayerBundleIdsAtLeastOnce
        ? orgCoupon.playerBundleIds
          ? Object.keys(orgCoupon.playerBundleIds)
          : []
        : initialPlayerBundleIds
    },
    { notifyOnMetaDataChanges: true }
  );

  useEffect(() => {
    if (playerBundles) {
      setHasLoadedInitialPlayerBundles(true);
    }
  }, [playerBundles]);

  let previewOrgCoupon: OrgCoupon | undefined = undefined;

  if (orgCoupon.useType && orgCoupon.type && orgCoupon.code) {
    if (orgCoupon.type === OrgCouponType.amountOff && orgCoupon.amountCents) {
      previewOrgCoupon = {
        type: OrgCouponType.amountOff,
        amountCents: orgCoupon.amountCents,
        code: orgCoupon.code,
        createdAtMS: Date.now(),
        id: "test-coupon",
        orgId: p.org.id,
        useType: orgCoupon.useType,
        expirationDateMS: orgCoupon.expirationDateMS
      };
    } else if (orgCoupon.type === OrgCouponType.percentOff && orgCoupon.percent) {
      previewOrgCoupon = {
        type: OrgCouponType.percentOff,
        code: orgCoupon.code,
        createdAtMS: Date.now(),
        id: "test-coupon",
        orgId: p.org.id,
        useType: orgCoupon.useType,
        expirationDateMS: orgCoupon.expirationDateMS,
        percent: orgCoupon.percent
      };
    }
  }

  const { orgSettings } = useOrgSettings({ orgId: p.org.id });

  const [errorMsg, setErrorMsg] = useState("");
  const [amountShadow, setAmountShadow] = useState(
    orgCoupon.type === OrgCouponType.amountOff && orgCoupon.amountCents ? String(orgCoupon.amountCents / 100) : ""
  );
  const [percentShadow, setPercentShadow] = useState(
    orgCoupon.type === OrgCouponType.percentOff && orgCoupon.percent ? String(orgCoupon.percent * 100) : ""
  );

  useEffect(() => {
    if (orgCoupon.useType === OrgCouponUseType.tryouts) {
      setIsPreviewExpanded(false);
    }
  }, [orgCoupon.useType]);

  if ((orgCoupon.playerBundleIds || p.playerBundleId) && !hasLoadedInitialPlayerBundles) {
    return <CenteredLoader />;
  }

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={
              p.type === "create" ? translate({ defaultMessage: "Create Coupon" }) : translate({ defaultMessage: "Edit Coupon" })
            }
            bottomButton={{
              title: p.type === "edit" ? translate.common.Save : translate.common.Create,
              enabled: isFormValid,
              onPress: async () => {
                if (orgCoupon.playerBundleIds && !orgCoupon.useLimit) {
                  const confirmed = window.confirm(
                    translate({
                      defaultMessage:
                        "You have created a player specific coupon without a use limit. We recommend adding a limit of at least 1. Are you you wish to proceed?"
                    })
                  );

                  if (!confirmed) {
                    return;
                  }
                }

                if (p.type === "create") {
                  const newOrgCoupon: DistributiveOmit<OrgCoupon, "id" | "createdAtMS"> = { ...orgCoupon } as DistributiveOmit<
                    OrgCoupon,
                    "id" | "createdAtMS"
                  >;
                  const { data: result } = await getBifrost().orgCoupon__client__addOrgCoupon.fetchClient({
                    orgCoupon: newOrgCoupon,
                    locale: getCurrentLocale()
                  });
                  if (result.type === "error") {
                    setErrorMsg(result.message);
                  } else {
                    p.onComplete?.();
                    p.onRequestDismiss();
                  }
                } else {
                  const { data: result } = await getBifrost().orgCoupon__client__updateOrgCoupon.fetchClient({
                    orgCoupon: orgCoupon as OrgCoupon,
                    locale: getCurrentLocale()
                  });
                  if (result.type === "error") {
                    setErrorMsg(result.message);
                  } else {
                    p.onComplete?.();
                    p.onRequestDismiss();
                  }
                }
              }
            }}
            onRequestDismiss={() => {
              if (
                !dequal(orgCoupon, initialOrgCoupon) &&
                !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
              ) {
                return;
              }

              p.onRequestDismiss();
            }}
          >
            <div>
              <div className="flex gap-2">
                <div className="flex-1 flex flex-col">
                  <PrettyCoolSelectInput
                    label={translate({ defaultMessage: "Use Type", description: "How the coupon will be used" })}
                    placeholder={translate.common.SelectDotDotDot}
                    value={orgCoupon.useType ?? ""}
                    isRequired
                    options={[
                      {
                        label: translate.common.Registration,
                        value: OrgCouponUseType.registration
                      },
                      {
                        label: translate.common.Invoices,
                        value: OrgCouponUseType.invoices
                      },
                      {
                        label: translate.common.Tryouts,
                        value: OrgCouponUseType.tryouts
                      },
                      {
                        label: translate.common.Camps,
                        value: OrgCouponUseType.camps
                      },
                      {
                        label: translate.common.OpenEvents,
                        value: OrgCouponUseType.genericEvents
                      },
                      {
                        label: translate.common.All,
                        value: OrgCouponUseType.all
                      }
                    ]}
                    onChange={newVal => {
                      setOrgCoupon({ useType: newVal as OrgCouponUseType });
                    }}
                  />
                </div>
                <div className="flex-1 flex flex-col">
                  <PrettyCoolSelectInput
                    label={translate.common.DiscountType}
                    placeholder={translate.common.SelectDotDotDot}
                    value={orgCoupon.type ?? ""}
                    isRequired
                    options={[
                      {
                        label: translate.common.Percent,
                        value: OrgCouponType.percentOff
                      },
                      {
                        label: translate.common.Amount,
                        value: OrgCouponType.amountOff
                      }
                    ]}
                    onChange={newVal => {
                      setOrgCoupon({ type: newVal as OrgCouponType });
                    }}
                  />
                </div>
              </div>
              <PrettyCoolTextInputWithLabel
                style={{ marginTop: 16 }}
                label={
                  !orgCoupon.type
                    ? translate.common.Amount
                    : orgCoupon.type === OrgCouponType.amountOff
                    ? translate.common.Amount + " $"
                    : `${translate.common.Amount} % (1-100)`
                }
                isRequired
                isMoney={orgCoupon.type === OrgCouponType.amountOff}
                isPercent={orgCoupon.type === OrgCouponType.percentOff}
                onChange={newVal => {
                  const newAmt = newVal?.match(/\d+\.?\d?\d?/)?.[0];
                  if (orgCoupon.type === OrgCouponType.amountOff) {
                    if (!newVal) {
                      setAmountShadow("");
                      setOrgCoupon({ amountCents: undefined });
                      return;
                    }

                    if (parseFloat(newAmt || "0") < 0) {
                      return;
                    }
                    setAmountShadow(newAmt || "");
                    setOrgCoupon({ amountCents: newAmt ? Number(newAmt) * 100 : 0 });
                  } else if (orgCoupon.type === OrgCouponType.percentOff) {
                    if (!newAmt) {
                      setOrgCoupon({ percent: undefined });
                      setPercentShadow("");
                      return;
                    }
                    let percent = parseFloat(newAmt);
                    const percentVal = percent ? percent / 100 : undefined;
                    if (percentVal && (percentVal <= 0 || percentVal > 1)) {
                      return;
                    }
                    setPercentShadow(newAmt || "");
                    setOrgCoupon({ percent: percentVal });
                  }
                }}
                inputProps={{ type: "number" }}
                value={`${
                  orgCoupon.type === OrgCouponType.amountOff
                    ? amountShadow
                    : orgCoupon.type === OrgCouponType.percentOff
                    ? percentShadow
                    : ""
                }`}
              />
              <PrettyCoolTextInputWithLabel
                style={{ marginTop: 16 }}
                label={translate({ defaultMessage: "Code", description: "Code as in coupon code" })}
                isRequired
                onChange={newCode => {
                  setOrgCoupon({ code: newCode ? newCode.replace(/[^a-zA-Z0-9]/g, "").toUpperCase() : "" });
                }}
                inputProps={{ type: "text" }}
                value={orgCoupon.code}
              />
              <div>
                <label className="text-xs text-gray-500">
                  {translate({
                    defaultMessage: "The code must be unique for your club."
                  })}
                </label>
              </div>

              <div className="flex flex-row mt-4">
                <PrettyCoolTextInputWithLabel
                  style={{ flex: 1, marginRight: 8 }}
                  infoTooltip={
                    orgCoupon.useType !== OrgCouponUseType.tryouts &&
                    orgCoupon.useType !== OrgCouponUseType.camps &&
                    orgCoupon.useType !== OrgCouponUseType.genericEvents
                      ? translate({
                          defaultMessage:
                            "If this coupon is assigned to players, this will be the max number of times each player can use the coupon. Otherwise, it's the total number of times this coupon can be used"
                        })
                      : undefined
                  }
                  label={`${
                    orgCoupon.playerBundleIds
                      ? translate({ defaultMessage: "Max # of Uses Per player" })
                      : translate({ defaultMessage: "Max # of Uses" })
                  } (${translate.common.Optional})`}
                  onChange={newUseLimit => {
                    const value = newUseLimit?.replaceAll(".", "");
                    if (!value) {
                      setOrgCoupon({ useLimit: undefined });
                      return;
                    }
                    const numValue = parseInt(value);
                    setOrgCoupon({ useLimit: numValue });
                  }}
                  inputProps={{ type: "number" }}
                  value={`${orgCoupon.useLimit ?? ""}`}
                />
                <div className="flex-1">
                  <PrettyCoolDateInput
                    validate={
                      p.type === "create"
                        ? val => {
                            if (moment(val) < moment()) {
                              return translate({ defaultMessage: "Expiration must be in the future" });
                            }
                            return "";
                          }
                        : undefined
                    }
                    isClearable
                    label={`${translate.common.Expiration} (${translate.common.Optional})`}
                    value={orgCoupon.expirationDateMS ? new Date(orgCoupon.expirationDateMS) : undefined}
                    onChange={newVal => {
                      if (newVal) {
                        setOrgCoupon({ expirationDateMS: moment(newVal).endOf("day").valueOf() });
                      } else {
                        setOrgCoupon({ expirationDateMS: undefined });
                      }
                    }}
                  />
                </div>
              </div>
              {orgCoupon.useType !== OrgCouponUseType.tryouts &&
              orgCoupon.useType !== OrgCouponUseType.camps &&
              orgCoupon.useType !== OrgCouponUseType.genericEvents ? (
                <div>
                  <div className="mt-4 mb-1">
                    <label className="text-sm font-extrabold">
                      {translate({ defaultMessage: "Player Assignment (Optional)" })}
                    </label>
                  </div>
                  <AsyncSelect
                    isMulti={true}
                    cacheOptions
                    isClearable
                    styles={{
                      dropdownIndicator: a => {
                        return {
                          ...a,
                          cursor: "pointer"
                        };
                      },

                      menu: curr => ({
                        ...curr,
                        position: "relative"
                      }),
                      menuPortal: a => ({ ...a, zIndex: 60 }),
                      clearIndicator: a => {
                        return {
                          ...a,
                          cursor: "pointer"
                        };
                      }
                    }}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    defaultValue={
                      playerBundles
                        ? playerBundles.map(ppb => {
                            return {
                              label: `${ppb.derived.accountInfo.firstName} ${ppb.derived.accountInfo.lastName}`,
                              value: ppb.playerBundle.id
                            };
                          })
                        : undefined
                    }
                    onChange={v => {
                      setOrgCoupon({
                        playerBundleIds: v.length
                          ? v.reduce((acc, val) => {
                              acc[val.value] = true;
                              return acc;
                            }, {} as Record<PlayerBundleId, true>)
                          : undefined
                      });
                    }}
                    loadingMessage={() => ""}
                    loadOptions={currValue => {
                      return getBifrost()
                        .playerBundle__server__searchInOrg.fetchServer({
                          orgId: p.org.id,
                          searchText: currValue,
                          selfAccountId: getCurrentUserAccountId()
                        })
                        .then(a =>
                          a.data.map(b => {
                            const playerName = `${b.playerBundle.virtualAthleteAccount.firstName.trim()} ${b.playerBundle.virtualAthleteAccount.lastName.trim()}`;
                            const guardianNames = b.guardianNames.length ? ` (${b.guardianNames.join(", ")})` : "";
                            return {
                              value: b.playerBundle.id,
                              label: `${playerName}${guardianNames} (${b.teamNames.join(", ")})`
                            };
                          })
                        );
                    }}
                    noOptionsMessage={() => ""}
                    placeholder={translate({ defaultMessage: "Type to search players..." })}
                    defaultOptions={[{ value: "", label: " " }]}
                  />
                </div>
              ) : null}
              {orgSettings ? (
                <View style={{ marginVertical: 16 }}>
                  <TextButton
                    iconElm={
                      isPreviewExpanded ? (
                        <ArrowUpIcon width={16} height={16} className="text-green-400" />
                      ) : (
                        <ArrowDownIcon width={16} height={16} className="text-green-400" />
                      )
                    }
                    text={`${isPreviewExpanded ? translate.common.HidePreview : translate.common.ViewPreview}${
                      orgCoupon.useType === OrgCouponUseType.tryouts
                        ? ` (${translate({ defaultMessage: "Not available for tryout coupons" })})`
                        : orgCoupon.useType === OrgCouponUseType.camps
                        ? ` (${translate({ defaultMessage: "Not available for camp coupons" })})`
                        : orgCoupon.useType === OrgCouponUseType.genericEvents
                        ? ` (${translate({ defaultMessage: "Not available for open event coupons" })})`
                        : ""
                    }`}
                    onPress={() => {
                      setIsPreviewExpanded(!isPreviewExpanded);
                    }}
                    disabled={
                      !previewOrgCoupon ||
                      orgCoupon.useType === OrgCouponUseType.tryouts ||
                      orgCoupon.useType === OrgCouponUseType.camps ||
                      orgCoupon.useType === OrgCouponUseType.genericEvents
                    }
                    textClassName="text-green-400"
                  />
                  <Expando durationMS={500} isExpanded={isPreviewExpanded}>
                    <View style={{ paddingHorizontal: 16 }}>
                      {previewOrgCoupon ? (
                        <OrgInvoicePreviewForCouponOrPaymentPlan
                          type={"coupon"}
                          org={p.org}
                          orgSettings={orgSettings}
                          orgCoupon={previewOrgCoupon}
                        />
                      ) : (
                        <StyledText style={{ marginTop: 16 }}>
                          {translate({
                            defaultMessage:
                              "Please make sure that all of the required fields are filled out in order to view the preview."
                          })}
                        </StyledText>
                      )}
                    </View>
                  </Expando>
                </View>
              ) : null}

              {errorMsg ? <Typography style={{ color: COLORS.red, marginTop: 30 }}>{errorMsg}</Typography> : null}
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}

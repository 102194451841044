import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { Org, OrgId } from "@ollie-sports/models";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import _ from "lodash";

export async function org__server__clearTeamLevelTryoutOverrides(p: { orgId: OrgId; selfAccountId: string }) {
  const { appOllieFirestoreV2: h, getAppPgPool } = getServerHelpers();
  console.log(p.orgId, "oTEQ7J5I1B");

  const org = await h.Org.getDoc(p.orgId);

  if (!org || !org.accounts[p.selfAccountId]?.exists) {
    throw new Error("Unauthorized access!");
  }

  const res = await getAppPgPool().query(
    `select t.id as team_id
from mirror_team t,
     mirror_teamsettings ts
where t.id = ts.id
  and t.item->>'orgId' = $1
  and (ts.item -> 'tryoutSettings' -> 'defaultOfferEmailText' is not null or
       ts.item -> 'tryoutSettings' -> 'defaultRejectionEmailText' is not null or
       ts.item -> 'tryoutSettings' -> 'defaultOfferExpirationTimeInHrs' is not null);`,
    [p.orgId]
  );

  const teamIdsToUpdate = res.rows.map(a => a["team_id"] as string);

  const chunks = _.chunk(teamIdsToUpdate, 25);
  for (let i = 0; i < chunks.length; i++) {
    const chunk = chunks[i];
    await Promise.all(
      chunk.map(async teamId => {
        return h.TeamSettings.update({ id: teamId, doc: { tryoutSettings: h._MagicDeleteValue } });
      })
    );
  }
}

org__server__clearTeamLevelTryoutOverrides.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete

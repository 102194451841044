import { translate } from "@ollie-sports/i18n";
import { PrettyCoolTextInputWithLabel, PrettyCoolTextAreaWithLabel, Form, FormRef } from "../../../components/Form";
import { CoolCheckboxInput } from "../../../components/Inputs/CoolCheckboxInput";
import { LINK_REGEX, EMAIL_REGEX } from "../../../utils/Regexes";
import { OrgSettings } from "@ollie-sports/models";
import { useImmutableState } from "../../../utils/useImmutableState";
import { useRef } from "react";
import { Button } from "@material-ui/core";
import { common__magicDeleteValue } from "@ollie-sports/core/dist/api";
import { getBifrost } from "../../../services/bifrost.service";
import { openErrorToast } from "../../../utils/openErrorToast";
import { InfoTooltipIcon } from "../../../components/InfoTooltip";
import { StyledButton } from "../../../components/StyledButton";
import { Prompt } from "react-router";
import { dequal } from "dequal";
import { useOrgSettings } from "../../../hooks/useOrgSettings";
import { deEqualIgnoring__UpdatedAtMS } from "./helpers";

export function OrgRegistrationSettings(p: { orgId: string; initialOrgSettings: OrgSettings }) {
  const [orgSettings, setOrgSettings] = useImmutableState<OrgSettings>(p.initialOrgSettings);
  const { orgSettings: subscribedOrgSettings } = useOrgSettings({ orgId: p.orgId });
  const formRef = useRef<FormRef>(null);

  return (
    <Form ref={formRef}>
      {() => (
        <>
          <Prompt
            message={translate({ defaultMessage: "Are you sure you wish to navigate? You have unsaved changes." })}
            when={!deEqualIgnoring__UpdatedAtMS(subscribedOrgSettings, orgSettings)}
          />

          <PrettyCoolTextInputWithLabel
            label={translate({ defaultMessage: "Default Registration Reply Email" })}
            infoTooltip={translate({
              defaultMessage:
                'The default "reply to" email address members see when receiving emails regarding their registration status, such as past due payments.'
            })}
            style={{ marginTop: 16 }}
            onChange={newVal => {
              if (!newVal) {
                setOrgSettings({
                  registrationSettings: {
                    ...orgSettings.registrationSettings,
                    defaultReplyToEmailAddress: undefined
                  }
                });
                return;
              }
              setOrgSettings({
                registrationSettings: {
                  ...orgSettings.registrationSettings,
                  defaultReplyToEmailAddress: newVal?.toLowerCase() ?? undefined
                }
              });
            }}
            validate={v => {
              if (v) {
                if (!v.match(EMAIL_REGEX)) {
                  return translate({ defaultMessage: "Must be a valid email" });
                }
              }
              return "";
            }}
            inputProps={{ className: "sm:max-w-xs", placeholder: translate({ defaultMessage: "Enter Email Address" }) }}
            value={orgSettings.registrationSettings?.defaultReplyToEmailAddress ?? ""}
          />
          <label className="flex text-sm font-extrabold items-center mt-4">
            {translate({ defaultMessage: "Registration Issue Email Summaries" })}{" "}
            {
              <InfoTooltipIcon
                title={translate({
                  defaultMessage:
                    "Sent every Friday alerting admins of players who are not registered or have registration issues (failed payment, etc.)"
                })}
              />
            }
          </label>
          <div className="ml-4">
            <CoolCheckboxInput
              style={{ marginTop: 4 }}
              label={translate.common.ClubAdmins}
              infoTooltip={translate({
                defaultMessage: "Club admins receive a summary of the number of players per team"
              })}
              value={orgSettings.registrationSettings?.incompleteRegistrationNotifications?.orgAdmins ?? false}
              labelType="inside"
              onChange={newVal => {
                setOrgSettings({
                  registrationSettings: {
                    ...orgSettings.registrationSettings,
                    incompleteRegistrationNotifications: {
                      ...(orgSettings.registrationSettings?.incompleteRegistrationNotifications ?? {}),
                      orgAdmins: newVal
                    }
                  }
                });
              }}
            />
            <CoolCheckboxInput
              style={{ marginTop: 4 }}
              label={translate.common.TeamAdmins}
              infoTooltip={translate({
                defaultMessage: "Team admins receive a list of players on their team"
              })}
              value={orgSettings.registrationSettings?.incompleteRegistrationNotifications?.teamAdmins ?? false}
              labelType="inside"
              onChange={newVal => {
                setOrgSettings({
                  registrationSettings: {
                    ...orgSettings.registrationSettings,
                    incompleteRegistrationNotifications: {
                      ...(orgSettings.registrationSettings?.incompleteRegistrationNotifications ?? {}),
                      teamAdmins: newVal
                    }
                  }
                });
              }}
            />
            <CoolCheckboxInput
              style={{ marginTop: 4 }}
              label={translate.common.Coaches}
              infoTooltip={translate({
                defaultMessage: "Coaches receive a list of players on their team"
              })}
              value={orgSettings.registrationSettings?.incompleteRegistrationNotifications?.coaches ?? false}
              labelType="inside"
              onChange={newVal => {
                setOrgSettings({
                  registrationSettings: {
                    ...orgSettings.registrationSettings,
                    incompleteRegistrationNotifications: {
                      ...(orgSettings.registrationSettings?.incompleteRegistrationNotifications ?? {}),
                      coaches: newVal
                    }
                  }
                });
              }}
            />
          </div>
          <label className="flex text-sm font-extrabold items-center mt-4">
            {translate({ defaultMessage: "Registration Reminders" })}{" "}
            {
              <InfoTooltipIcon
                title={translate({
                  defaultMessage:
                    "Emails and app notifications sent weekly, 3 days before/after, 1 day before/after registration is due if the player is not registered or has registration issues (failed payment, etc.)"
                })}
              />
            }
          </label>
          <div className="ml-4">
            <CoolCheckboxInput
              style={{ marginTop: 4 }}
              label={translate.common.Players + "/" + translate.common.Guardians}
              value={orgSettings.registrationSettings?.incompleteRegistrationNotifications?.individuals ?? false}
              labelType="inside"
              onChange={newVal => {
                setOrgSettings({
                  registrationSettings: {
                    ...orgSettings.registrationSettings,
                    incompleteRegistrationNotifications: {
                      ...(orgSettings.registrationSettings?.incompleteRegistrationNotifications ?? {}),
                      individuals: newVal
                    }
                  }
                });
              }}
            />
          </div>

          <CoolCheckboxInput
            style={{ marginTop: 16 }}
            label={translate({ defaultMessage: "Only Allow Club Admins To Mark Additional Steps As Completed" })}
            infoTooltip={translate({
              defaultMessage: "Setting that requires a person to be a club admin to mark additional steps as completed."
            })}
            value={orgSettings.registrationSettings?.restrictAdditionalStepsToAdmins ?? false}
            labelType="inside"
            onChange={newVal => {
              setOrgSettings({
                registrationSettings: {
                  ...orgSettings.registrationSettings,
                  restrictAdditionalStepsToAdmins: !orgSettings.registrationSettings?.restrictAdditionalStepsToAdmins
                }
              });
            }}
          />

          <StyledButton
            className="mt-4"
            text={translate.common.Save}
            onClick={async () => {
              try {
                await getBifrost().org__server__updateOrgSettings.fetchServer({
                  id: p.initialOrgSettings.id,
                  orgSettings: {
                    registrationSettings: {
                      defaultReplyToEmailAddress:
                        orgSettings.registrationSettings?.defaultReplyToEmailAddress ?? common__magicDeleteValue(),
                      restrictAdditionalStepsToAdmins:
                        orgSettings.registrationSettings?.restrictAdditionalStepsToAdmins ?? common__magicDeleteValue(),
                      incompleteRegistrationNotifications:
                        orgSettings.registrationSettings?.incompleteRegistrationNotifications ?? {}
                    }
                  }
                });
              } catch (e) {
                openErrorToast(
                  translate({
                    defaultMessage:
                      "There was a problem updating the settings. Please try again or contact support@olliesports.com"
                  })
                );
              }
            }}
          />
        </>
      )}
    </Form>
  );
}

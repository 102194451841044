import { Typography } from "@material-ui/core";
import _ from "lodash";
import {
  Org,
  OrgCoupon,
  // OrgCouponPaymentPlanApplicationMethod,
  OrgCouponType,
  OrgId,
  PlayerBundleId
} from "@ollie-sports/models";
import { getCurrentLocale, translate } from "@ollie-sports/i18n";
import { getBifrost } from "../../services/bifrost.service";
import { COLORS } from "@ollie-sports/core";
import { useEffect, useState } from "react";
import { useImmutableState } from "../../utils/useImmutableState";
import {
  Form,
  PrettyCoolDateInput,
  PrettyCoolSelectInput,
  PrettyCoolTextAreaWithLabel,
  PrettyCoolTextInputWithLabel
} from "../../components/Form";
import moment from "moment";
import { DistributiveOmit } from "react-redux";
import AsyncSelect from "react-select/async";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { usePromise } from "../../utils/hooks/usePromise";
import { openModal } from "../../components/modals/imperativeModal";
import { dequal } from "dequal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { useOrgSettings } from "../../hooks/useOrgSettings";
import { View } from "react-native-web";
import { TextButton } from "../../components/TextButton";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import { Expando } from "../../components/Expando";
import { OrgInvoicePayPreviewInner, OrgInvoicePreviewForCouponOrPaymentPlan } from "./OrgInvoicePayPreview";
import { StyledText } from "../../components/StyledText";
import { CenteredLoader } from "../../components/CenteredLoader";

type Props = {
  orgId: OrgId;
  playerBundleId: PlayerBundleId;
  onComplete: () => Promise<void>;
};

export function openOrgPlayerBundleNoteAddModal(p: Props) {
  return new Promise<void>(res => {
    const modal = openModal({
      body: (
        <OrgPlayerBundleNoteAddModal
          {...p}
          onRequestDismiss={() => {
            modal.close();
            res();
          }}
        />
      )
    });
  });
}

function OrgPlayerBundleNoteAddModal(p: Props & { onRequestDismiss: () => void }) {
  const [errorMsg, setErrorMsg] = useState("");
  const [note, setNote] = useState("");
  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={translate({ defaultMessage: "New Note" })}
            bottomButton={{
              title: translate.common.Save,
              enabled: isFormValid,
              onPress: async () => {
                try {
                  await getBifrost().playerBundleNote__client__addNote.fetchClient({
                    accountId: getCurrentUserAccountId(),
                    note,
                    orgId: p.orgId,
                    playerBundleId: p.playerBundleId
                  });
                  await p.onComplete();
                  p.onRequestDismiss();
                } catch (e) {
                  setErrorMsg(translate.common.SomethingWentWrong);
                }
              }
            }}
            onRequestDismiss={() => {
              p.onRequestDismiss();
            }}
          >
            <div>
              <PrettyCoolTextAreaWithLabel
                label={translate.common.Note}
                isRequired
                onChange={newVal => {
                  setNote(newVal ?? "");
                }}
                value={note}
              />

              {errorMsg ? <Typography style={{ color: COLORS.red, marginTop: 30 }}>{errorMsg}</Typography> : null}
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}
